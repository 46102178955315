export default {
  title: "التواصل معنا",
  subtitle: `هل ترغب في معرفة المزيد
              <br /> عن دوراتنا؟`,
  phone: "54041 525 151 49+",
  address: "برلين، ألمانيا",
  fullName: "الاسم الكامل",
  email: "البريد الإلكتروني",
  subject: "الموضوع",
  message: "رسالة",
  submit: "إرسال الرسالة",
  sending: "جارٍ إرسال الرسالة...",
  success: "تم إرسال رسالتك",
  failed: "فشل في إرسال الرسالة",
};
