export default {
  title: "Getting Touch",
  subtitle: `Want to learn more
              <br /> about our courses?`,
  phone: "+49 151 525 54041",
  address: "Berlin, Germany",
  fullName: "Full Name",
  email: "Email",
  subject: "Subject",
  message: "Message",
  submit: "Send Message",
  sending: "Sending Message...",
  success: "Your message has been sent",
  failed: "Failed to send message",
};
