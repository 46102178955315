export default {
  title: "Kontaktieren Sie Uns",
  subtitle: `Möchten Sie mehr
            <br /> erfahren über unsere Kurse?`,
  phone: "+49 151 525 54041",
  address: "Berlin, Deutschland",
  fullName: "Vollständiger Name",
  email: "E-Mail",
  subject: "Betreff",
  message: "Nachricht",
  submit: "Nachricht Senden",
  sending: "Nachricht wird gesendet...",
  success: "Ihre Nachricht wurde gesendet",
  failed: "Nachricht konnte nicht gesendet werden",
};
