export default {
  title: "Nous Contacter",
  subtitle: `Vous souhaitez en savoir
              <br /> plus sur nos cours ?`,
  phone: "+49 151 525 54041",
  address: "Berlin, Allemagne",
  fullName: "Nom Complet",
  email: "Email",
  subject: "Sujet",
  message: "Message",
  submit: "Envoyer le Message",
  sending: "Envoi du message...",
  success: "Votre message a été envoyé",
  failed: "Échec de l'envoi du message",
};
